import { createContext, useContext } from "react";
import AppStore from "./appStore";
import ApiStore from "./apiStore";

export const store = {
  Appstore: new AppStore(),
  ApiStore: new ApiStore(),
};

export const StoreContext = createContext(store);

export const StoreProvider = (props: any) => {
  return (
    <StoreContext.Provider value={store}>
      {props.children}
    </StoreContext.Provider>
  );
};

export function useStore() {
  return useContext(StoreContext);
} 
