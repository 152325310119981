import Head from "next/head";
import Script from "next/script";
import type React from "react";
import { useEffect } from "react";
import { StoreProvider } from "../context/store";
import "../styles/globals.css";
import { init } from "../utils/sentry";
import useGtag from "../utils/useGtag";
import { useState } from "react"
import { AppErrorFallback } from "../components/AppErrorFallback";
import { useRouter } from "next/router";
import { ErrorBoundary } from "react-error-boundary";
import ApiStore from "../context/apiStore";
import AppStore from "../context/appStore";
import "../scss/main.scss";
import * as idb from "idb-keyval";
// import { appWithTranslation } from 'next-i18next';
import { IntlProvider } from "react-intl";
import en from "../i18n/en.json";
import ar from "../i18n/ar.json";

init();

const APP_NAME = "sketshare";
const APP_DESCRIPTION = "Sketshare is a virtual whiteboard with real-time collaboration.";
const APP_URL = "https://sketshare.com";
const IMAGE = "https://sketshare.com/social-image.png";

function MyApp({ Component, pageProps }: any) {
  useGtag();
  const [errorInfo, setErrorInfo] = useState<React.ErrorInfo | null>(null);

  const router = useRouter()
  const Apistore = new ApiStore();
  const Appstore = new AppStore();
  const { locale } = useRouter();

  const messages = {
    en,
    ar
  }

  const setUserData = () => {
    const result = JSON.parse(window.localStorage.getItem("sketshare_user"))
    if (result) {
      Apistore.setToken(result?.token)
      Apistore.setUserData(result?.user)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      setUserData()
      if (router.pathname !== "/canvas") {
        window.localStorage.removeItem("load_canvas_data")
        window.localStorage.removeItem("saved_canvas_data")
      }
      if (router.pathname !== '/canvas/[roomName]') {
        Apistore.setAutoSaveCanvas(false)
      }
    }
    if (router.pathname === "/canvas" || router.pathname === '/canvas/[roomName]') {
      idb.get('is-collaborator').then((val) => {
        if (val) {
          Appstore.setIsCollaborator(val)
        }
      })
    } else {
      idb.del('is-collaborator')
    }

  }, [router]);

  return (
    <>
      <Head>
        <meta name="application-name" content={APP_NAME} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="apple-mobile-web-app-title" content={APP_NAME} />
        <meta name="description" content={APP_DESCRIPTION} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="theme-color" content="#fafafa" />

        <meta name="twitter:url" content={APP_URL} />
        <meta name="twitter:title" content={APP_NAME} />
        <meta name="twitter:description" content={APP_DESCRIPTION} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@sketshare" />
        <meta name="twitter:site" content="@sketshare" />
        <meta name="twitter:image" content={IMAGE} />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={APP_NAME} />
        <meta property="og:description" content={APP_DESCRIPTION} />
        <meta property="og:site_name" content={APP_NAME} />
        <meta property="og:url" content={APP_URL} />
        <meta property="og:image" content={IMAGE} />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"
        />

        <link rel="manifest" href="/manifest.json" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="shortcut icon" href="/favicon.ico" hrefLang="x-default" />
        <link rel="shortcut icon" href="/favicon.ico" hrefLang="en" />
        <link rel="shortcut icon" href="/favicon.ico" hrefLang="ar" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/icons/apple-touch-icon.png"
        />

        <title>Sketshare</title>
      </Head>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=G-4R7QTQS0LS`}
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-4R7QTQS0LS', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <Script id="hotjar">
        {`
           (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:3068997,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
      </Script>
      <StoreProvider>
        <IntlProvider locale={locale} messages={messages[locale]}>
        <ErrorBoundary
          onError={(error, info) => {
            if (process.env.NODE_ENV === 'production') {
              console.log("error_boundary: ", error, info);
            }
            setErrorInfo(info);
            console.log("error_boundary: ", error, info);
          }}
          fallbackRender={fallbackProps => {
            return <AppErrorFallback {...fallbackProps} errorInfo={errorInfo} />;
          }}
        >
          <Component {...pageProps} />
        </ErrorBoundary>
        </IntlProvider>
      </StoreProvider>
    </>
  );
}

export default MyApp;
