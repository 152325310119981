import { makeAutoObservable } from "mobx";
import * as idb from "idb-keyval";
import { profileData } from "../helpers/models/profileDataModel";
import { NewCanvasDataModel } from "../helpers/models/newCanvasDataModel";

export default class ApiStore {
  token: string = "";
  loginStatus: boolean = false;
  userData: profileData.Data;
  canvasData: NewCanvasDataModel.RootObject;
  searchDashboard: string = "";
  canvasToLoad: boolean = false;
  isCanvasSaved: boolean = false;
  isCanvasToSave: boolean = true;
  newCanvasIsSaved: string = "";
  autoSaveCanvas: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setToken(token: string) {
    this.token = token;
    idb.set("sketshare_usertoken", token).catch((e) => console.error(e));
  }

  setLoginStatus(loginStatus: boolean) {
    this.loginStatus = loginStatus;
  }

  setUserData(userData: profileData.Data) {
    this.userData = userData;
    idb.set("sketshare_userdata", userData).catch((err) => console.log(err));
  }

  setCanvasData(canvasData: NewCanvasDataModel.RootObject) {
    this.canvasData = canvasData;
  }

  setsearchDashboard(text: string) {
    this.searchDashboard = text;
  }

  setCanvasToLoad(load: boolean) {
    this.canvasToLoad = load;
  }

  setIsCanvasSaved(isCanvasSaved: boolean) {
    this.isCanvasSaved = isCanvasSaved
  }

  setIsCanvasToSave(isCanvasToSave: boolean) {
    this.isCanvasToSave = isCanvasToSave
  }

  setNewCanvasIsSaved(newCanvasIsSaved: string) {
    this.newCanvasIsSaved = newCanvasIsSaved
  }

  setAutoSaveCanvas(autoSaveCanvas: boolean) {
    this.autoSaveCanvas = autoSaveCanvas
  }
}
