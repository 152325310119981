import { makeAutoObservable } from "mobx";
import * as idb from "idb-keyval"

export default class AppStore {
  room_Id: string = "";
  isRoom: boolean = false;
  videoCallWindow: boolean = false;
  userName: string = Date.now().toString();
  role: string = "";
  isCollaborator: boolean = false;
  previewScreen: boolean = true;
  videoStream: any;
  audioStream: any;

  constructor() {
    makeAutoObservable(this);
  }

  setRoom_Id(room_Id: string) {
    this.room_Id = room_Id;
  }

  setUserName(userName: string) {
    this.userName = userName;
  }

  setRole(role: string) {
    this.role = role;
  }

  setIsRoom(isRoom: boolean) {
    this.isRoom = isRoom;
  }

  setVideoCallWindow(videoCallWindow: boolean) {
    this.videoCallWindow = videoCallWindow;
  }

  setIsCollaborator(isCollaborator: boolean) {
    this.isCollaborator = isCollaborator
    idb.set('is-collaborator', isCollaborator)
  }

  setPreviewScreen(previewScreen: boolean) {
    this.previewScreen = previewScreen
  }

  setVideoStream(videoStream: any) {
    this.videoStream = videoStream
  }

  setAudioStream(audioStream: any) {
    this.audioStream = audioStream
  }
}
